import React from "react";
import { getSavedLessons } from "../../services/DashboardServices";
import {
  getAllBoards,
  getAllClasses,
  getAllMediums,
} from "../../services/ProfileServices";
import { parseJwt } from "../../services/UtilService";
import { IVideosDetails } from "../../vm";
import Loading from "../common/Loading";
import NoData from "../common/NoData";
import { ToastContext } from "../common/ToastProvider";
import "../style.css";
import ViewLesson from "./ViewLesson";

export interface SavedLessonProps {}

const SavedLesson: React.FC<SavedLessonProps> = () => {
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    savedLessons: [],
    showVideoPage: false,
    videoObj: {},
  } as {
    isLoading: boolean;
    savedLessons: IVideosDetails[];
    showVideoPage: boolean;
    videoObj: IVideosDetails;
    className: string;
    boardName: string;
    mediumName: string;
  });

  // hooks
  React.useEffect(() => {
    const asynFunc = async () => {
      await getClassDetails();
      document.querySelector(".sideBar")?.classList.add("showSideBar");
      document.querySelector("#main_containerin")?.classList.add("ml-300px");
    };
    asynFunc();
  }, []);

  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  const getClassDetails = async () => {
    setState({ ...state, isLoading: true });
    let result = await getAllClasses();
    if (result && result.status) {
      getMediumDetails(result.data);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error while getting class details",
        "error"
      );
    }
  };

  const getMediumDetails = async (allClasses: any) => {
    setState({ ...state, isLoading: true });
    let result = await getAllMediums();
    if (result && result.status) {
      getBoardDetails(allClasses, result.data.getMedium);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error while getting medium details",
        "error"
      );
    }
  };

  const getBoardDetails = async (allClasses: any, allMediums: any) => {
    setState({ ...state, isLoading: true });

    const preferences = parseJwt();
    // console.log("pn", preferences);

    let result = await getAllBoards();
    if (result && result.status) {
      let obj = {
        ...state,
        name: preferences.name,
        imageUrl: preferences.image_url,
        className: allClasses?.find(
          (c: any) => c.class_id === preferences.class_id
        )?.class_name,
        boardName: result.data.getBoard.find(
          (b) => b.board_id === preferences.board_id
        )?.board_name,
        mediumName: allMediums?.find(
          (m: any) => m.medium_id === preferences.medium_id
        )?.medium,
      };
      setState(obj);
      getSavedLesson(obj);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error while getting board details",
        "error"
      );
    }
  };
  const getSavedLesson = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState != null) {
      localState = { ...paramState };
    }
    setState({ ...localState, isLoading: true });
    let result = await getSavedLessons();
    if (result && result.status) {
      setState({
        ...localState,
        savedLessons: result.data,
        isLoading: false,
      });
    } else {
      setState({ ...localState, isLoading: false });
      showToast(
        result?.message || "Error while getting saved lessons",
        "error"
      );
    }
  };

  const showVideoPage = (ele: IVideosDetails) => {
    setState({
      ...state,
      showVideoPage: true,
      videoObj: ele,
    });
  };

  const handleShowVideoPage = () => {
    setState({ ...state, showVideoPage: false });
  };

  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      {!state.showVideoPage && (
        <div className="contentColm" style={{ marginTop: 80 }}>
          <div className="row">
            <h4 style={{ marginLeft: 15 }} className="text-bold">
              My Saved Lessons
            </h4>
          </div>
          {state.savedLessons && state.savedLessons.length > 0 ? (
            state.savedLessons.map((lesson) => {
              return (
                <div className="vidoDetailClm">
                  <div className="row" onClick={() => showVideoPage(lesson)}>
                    <div className="col-sm-3">
                      <img
                        src={
                          lesson?.thumbnail
                            ? lesson.thumbnail
                            : "/images/thumb1.jpg"
                        }
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-sm-8">
                      <h5 className="text-bold">{lesson?.video_title || ""}</h5>
                      <div className="tagsLink">
                        <p>{state.boardName}</p>
                        <p>{state.mediumName}</p>
                        <p>Class {state.className || 1}</p>
                        {/* <p>
                      {
                        student.allSubjects.find(
                          (sub) => sub.subject_id === params.subjectId
                        )?.subject
                      }
                    </p>
                    <p>
                      {
                        state.chapters.find(
                          (c) => c.chapter_id === state.chapterId
                        )?.chapter_name
                      }
                    </p> */}
                      </div>
                      <p>{lesson.description}</p>
                      {/* <p className="pubDate">
                              Published on{" "}
                              <span className="publish_Date">02 Sept 2020</span>
                            </p> */}
                    </div>
                    {/* <div className="col-sm-1">
                      <img
                        src={
                          lesson?.is_clapped
                            ? "/images/applause_yellow.png"
                            : "/images/applause_white.png"
                        }
                        className="img-fluid"
                        alt=""
                        style={{ width: 30 }}
                      />
                    </div> */}
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ marginTop: 200 }}>
              {/*  <h5 style={{ textAlign: "center", marginTop: 200 }}>
              No Saved lessons found
            </h5> */}
              <NoData msg="No Saved lessons found" />
            </div>
          )}
        </div>
      )}

      {state.showVideoPage && (
        <ViewLesson
          videoObj={state.videoObj}
          //   educatorObj={state.educatorObj}
          handleShowVideoPage={handleShowVideoPage}
          //   chapters={state.chapters}
          //   chapterId={state.chapterId}
          className={state.className}
          boardName={state.boardName}
          mediumName={state.mediumName}
          savedLesson={true}
        />
      )}
    </React.Fragment>
  );
};

export default SavedLesson;
