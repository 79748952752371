import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthenticatedRoute } from "./components/common/AuthenticatedRoute";
import DashboardMain from "./components/dashboard/DashboardMain";
import LoginMain from "./components/login/LoginMain";
import WelcomeMain from "./components/welcome/WelcomeMain";
import SavedLesson from "./components/dashboard/SavedLesson";

export interface RoutesMainProps {}

const RoutesMain: React.FC<RoutesMainProps> = () => {
  return (
    <React.Fragment>
      <main>
        <Switch>
          <Route path="/" exact component={LoginMain} />
          <AuthenticatedRoute
            path="/subject/:subjectId/lessons"
            exact
            component={DashboardMain}
          />
          <AuthenticatedRoute path="/profile" exact component={WelcomeMain} />
          <AuthenticatedRoute
            path="/saved-lessons"
            exact
            component={SavedLesson}
          />
        </Switch>
      </main>
    </React.Fragment>
  );
};

export default RoutesMain;
