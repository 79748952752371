import { ILogin, ILoginProfile, ISignup, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";

export async function loginToApp(
    cred: ILogin
): Promise<IStandardAPIResponse<{ token: string, profile: ILoginProfile, preferences: any[] }>> {
    try {
        let res = await httpClient<{ token: string, profile: ILoginProfile, preferences: any[] }>(`login`, 'POST', cred);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function signupToApp(
    cred: ISignup
): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`signup`, 'POST', cred);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}
