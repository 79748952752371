import React from "react";
import "./App.css";
import { ToastProvider } from "./components/common/ToastProvider";
import { ProfileProvider } from "./components/common/ProfileProvider";
import Routes from "./Routes";

function App() {
  return (
    <React.Fragment>
      <ToastProvider>
        <ProfileProvider>
          <Routes />
        </ProfileProvider>
      </ToastProvider>
    </React.Fragment>
  );
}

export default App;
