import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import NavBar from "./components/common/NavBar";
import Sidebar from "./components/dashboard/Sidebar";
import RoutesMain from "./RoutesMain";
export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = (props) => {
  return (
    <React.Fragment>
      <Router>
        <NavBar />
        <Sidebar />
        <main id="main_containerin">
          <RoutesMain />
        </main>
      </Router>
    </React.Fragment>
  );
};

export default Routes;
