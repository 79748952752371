import {
  IChapter,
  IChapterDetails,
  ILessonDetails,
  IStandardAPIResponse,
  ISubjectDetails,
  IVideosDetails,
} from "../vm";
import { httpClient } from "./UtilService";

export async function getAllSubjects(data: {
  board_id: number;
  class_id: number;
  medium_id: number;
}): Promise<IStandardAPIResponse<Array<ISubjectDetails>>> {
  try {
    let res = await httpClient<Array<ISubjectDetails>>(
      `get_subjects`,
      "GET",
      data
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getAllChapters(data: {
  board_id: number;
  class_id: number;
  medium_id: number;
  subject_id: number;
}): Promise<IStandardAPIResponse<IChapter>> {
  try {
    let res = await httpClient<IChapter>(`get_chapters`, "GET", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getAllLessons(data: {
  board_id: number;
  class_id: number;
  medium_id: number;
  subject_id: number;
  chapter_id: number;
  lesson_id?: number;
}): Promise<IStandardAPIResponse<ILessonDetails>> {
  try {
    let res = await httpClient<ILessonDetails>(`chapter_details`, "POST", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getAllVideos(data: {
  chapter_id: number;
  educator_id: number;
  lesson_id?: number;
}): Promise<IStandardAPIResponse<Array<IVideosDetails>>> {
  try {
    let res = await httpClient<Array<IVideosDetails>>(
      `get_videos`,
      "GET",
      data
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getSavedLessons(): Promise<
  IStandardAPIResponse<Array<IVideosDetails>>
> {
  try {
    let res = await httpClient<Array<IVideosDetails>>(`get_savedlesson`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function addSavedLessons(data: {
  userID: number;
  lessonID: number;
}): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`saveLesson`, "POST", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function addClapLessons(data: {
  lessonID: number;
}): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`create_clapLesson`, "POST", data);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
