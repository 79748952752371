import * as React from "react";
import {
  addSavedLessons,
  getAllChapters,
  getAllLessons,
  getAllSubjects,
  getAllVideos,
} from "../../services/DashboardServices";
import { parseJwt, removeToken } from "../../services/UtilService";
import {
  IChapterDetails,
  ILessonDetails,
  ISubjectDetails,
  IVideosDetails,
} from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import "../style.css";
import {
  getAllBoards,
  getAllClasses,
  getAllMediums,
} from "../../services/ProfileServices";
import Loading from "../common/Loading";
import { Link, useHistory, useParams } from "react-router-dom";
import ViewLesson from "./ViewLesson";
import NoData from "../common/NoData";

export interface DashboardMainProps {}

const DashboardMain: React.FC<DashboardMainProps> = () => {
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    // subjectId: undefined,
    chapterId: undefined,
    // allSubjects: [],
    chapters: [],
    educators: {},
    videosDict: {},
    showVideoPage: false,
    videoObj: {},
    educatorObj: {
      educatorName: "",
      educatorImageUrl: "",
    },
    videosDictKey: undefined,
    index: undefined,
  } as {
    isLoading: boolean;
    // subjectId: number;
    chapterId: number;
    // allSubjects: ISubjectDetails[];
    chapters: IChapterDetails[];
    educators: ILessonDetails;
    videosDict: any;
    showVideoPage: boolean;
    videoObj: IVideosDetails;
    educatorObj: {
      educatorName: string;
      educatorImageUrl: string;
    };
    videosDictKey: number;
    index: number;
  });

  const [student, setStudent] = React.useState({
    isLoading: false,
    name: "",
    className: "",
    boardName: "",
    mediumName: "",
    imageUrl: "",
    allSubjects: [],
  } as {
    isLoading: boolean;
    name: string;
    className: string;
    boardName: string;
    mediumName: string;
    imageUrl: string;
    allSubjects: ISubjectDetails[];
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const params: any = useParams();
  // const history = useHistory();

  // hooks
  React.useEffect(() => {
    const asyncFunc = async () => {
      const preferences = parseJwt();
      await getChapters(parseInt(params.subjectId), preferences);
      await getClassDetails();
      document.querySelector(".sideBar")?.classList.add("showSideBar");
      document.querySelector("#main_containerin")?.classList.add("ml-300px");
    };
    asyncFunc();
  }, [params.subjectId]);

  const getClassDetails = async () => {
    setStudent({ ...student, isLoading: true });
    let result = await getAllClasses();
    if (result && result.status) {
      getMediumDetails(result.data);
    } else {
      setStudent({ ...student, isLoading: false });
      showToast(
        result?.message || "Error while getting class details",
        "error"
      );
    }
  };

  const getMediumDetails = async (allClasses: any) => {
    setStudent({ ...student, isLoading: true });
    let result = await getAllMediums();
    if (result && result.status) {
      getBoardDetails(allClasses, result.data.getMedium);
    } else {
      setStudent({ ...student, isLoading: false });
      showToast(
        result?.message || "Error while getting medium details",
        "error"
      );
    }
  };

  const getBoardDetails = async (allClasses: any, allMediums: any) => {
    setStudent({ ...student, isLoading: true });

    const preferences = parseJwt();
    console.log("pn", preferences);

    let result = await getAllBoards();
    if (result && result.status) {
      let obj = {
        ...student,
        name: preferences?.name,
        imageUrl: preferences?.image_url,
        className: allClasses?.find(
          (c: any) => c.class_id === preferences?.class_id
        )?.class_name,
        boardName: result.data.getBoard.find(
          (b) => b.board_id === preferences?.board_id
        )?.board_name,
        mediumName: allMediums?.find(
          (m: any) => m.medium_id === preferences?.medium_id
        )?.medium,
      };
      setStudent(obj);
      getSubjects(obj);
    } else {
      setStudent({ ...student, isLoading: false });
      showToast(
        result?.message || "Error while getting board details",
        "error"
      );
    }
  };

  const getSubjects = async (paramState?: any) => {
    let localState = { ...student };
    if (paramState != null) {
      localState = { ...paramState };
    }
    setStudent({ ...localState, isLoading: true });

    const preferences = parseJwt();
    // console.log("p", preferences);

    let result = await getAllSubjects({
      board_id: preferences?.board_id,
      class_id: preferences?.class_id,
      medium_id: preferences?.medium_id,
      // board_id: 2,
      // class_id: 1,
      // medium_id: 1,
    });
    if (result && result.status) {
      setStudent({
        ...localState,
        allSubjects: result.data,
        isLoading: false,
      });
      // getChapters(result.data[0]?.subject_id, preferences, obj);
    } else {
      setStudent({
        ...localState,
        isLoading: false,
      });
      showToast(result?.message || "Error while getting subject list", "error");
    }
  };

  const getChapters = async (
    subjectId: number,
    preferences: any,
    paramState?: any
  ) => {
    let localState = { ...state };
    if (paramState != null) {
      localState = { ...paramState };
    }
    setState({ ...localState, isLoading: true });
    let result = await getAllChapters({
      board_id: preferences?.board_id,
      class_id: preferences?.class_id,
      medium_id: preferences?.medium_id,
      subject_id: subjectId,
      // languages: preferences.languages,
      // board_id: 2,
      // class_id: 1,
      // medium_id: 1,
    });
    if (result && result.status) {
      let obj = {
        ...localState,
        chapters: result.data.get_chapters,
        // subjectId,
        chapterId: result.data.get_chapters[0]?.chapter_id,
        isLoading: false,
      };
      setState(obj);
      getLessons(
        subjectId,
        result.data.get_chapters[0]?.chapter_id,
        preferences,
        obj
      );
    } else {
      setState({
        ...state,
        isLoading: false,
      });
      showToast(result?.message || "Error while getting chapter list", "error");
    }
  };

  const getLessons = async (
    subjectId: number,
    chapterId: number,
    preferences: any,
    paramState?: any
  ) => {
    let localState = { ...state };
    if (paramState != null) {
      localState = { ...paramState };
    }
    setState({ ...localState, isLoading: true });
    let result = await getAllLessons({
      board_id: preferences?.board_id,
      class_id: preferences?.class_id,
      medium_id: preferences?.medium_id,
      subject_id: subjectId,
      chapter_id: chapterId,
      // board_id: 2,
      // class_id: 1,
      // medium_id: 1,
    });
    if (result && result.status) {
      let videosDict = {};
      for (let i = 0; i < result.data?.educators_content?.length; i++) {
        let edu = result.data.educators_content[i];
        let videos = await getAllVideos({
          chapter_id: chapterId,
          educator_id: edu.id,
        });
        videosDict[edu.id] = {
          ...edu,
          data: [],
        };
        if (videos && videos.status) {
          if (videos.data.length > 0) {
            videos.data.forEach((video) => {
              videosDict[edu.id].data.push(video);
            });
          }
        }
      }
      // result.data.educators_content.forEach(async (edu) => {
      //   let videos = await getAllVideos({
      //     chapter_id: chapterId,
      //     educator_id: edu.id,
      //   });
      //   videosDict[edu.id] = {
      //     ...edu,
      //     data: [],
      //   };
      //   if (videos && videos.status) {
      //     videos.data.forEach((video) => {
      //       videosDict[edu.id].data.push(video);
      //     });
      //   }
      // });
      console.log("vd", videosDict);

      setState({
        ...localState,
        educators: result.data,
        videosDict,
        // subjectId,
        chapterId,
        isLoading: false,
      });
    } else {
      setState({
        ...state,
        isLoading: false,
      });
      showToast(result?.message || "Error while getting lessons list", "error");
    }
  };

  // const onSelectSubject = async (subjectId: number) => {
  //   const preferences = parseJwt();
  //   await getChapters(subjectId, preferences);
  // };

  const onSelectChapter = async (e: any) => {
    const preferences = parseJwt();
    await getLessons(
      parseInt(params.subjectId),
      parseInt(e.target.value),
      preferences
    );
  };

  const showVideoPage = (
    ele: IVideosDetails,
    educatorName: string,
    educatorImageUrl: string,
    videosDictKey: number,
    index: number
  ) => {
    setState({
      ...state,
      showVideoPage: true,
      videoObj: ele,
      educatorObj: { educatorName, educatorImageUrl },
      videosDictKey,
      index,
    });
  };

  const handleShowVideoPage = () => {
    setState({ ...state, showVideoPage: false, index: undefined });
  };

  const savedALesson = () => {
    let videosDict = { ...state.videosDict };
    if (state.index !== undefined && state.videosDictKey !== undefined) {
      videosDict[state.videosDictKey].data[state.index].is_saved = true;
    }
    setState({
      ...state,
      videosDict,
      isLoading: false,
    });
  };

  const clapedALesson = () => {
    let videosDict = { ...state.videosDict };
    if (state.index !== undefined && state.videosDictKey !== undefined) {
      videosDict[state.videosDictKey].data[state.index].is_clapped = true;
    }
    setState({
      ...state,
      videosDict,
      isLoading: false,
    });
  };

  // const onLogout = () => {
  //   removeToken();
  // };

  return (
    <React.Fragment>
      {(state.isLoading || student.isLoading) && (
        <Loading loadingText="Loading, please wait..." />
      )}
      {/* <div className="bodyColm"> */}
      {/* <div className="sideBar">
          <div className="profileDetail">
            <div className="picDet">
              <img
                src={
                  student.imageUrl ? student.imageUrl : "/images/student.jpg"
                }
                alt=""
                className="img-fluid"
              />
              <h5>{student.name}</h5>
              <h6>
                Class {student.className || 1} {student.boardName}
              </h6>
              <h6>{student.mediumName} Medium</h6>
            </div>

            <div className="stdntDetails">
              <h5>My Subjects</h5>
              <ul>
                {state.allSubjects &&
                  state.allSubjects.map((sub) => {
                    return (
                      <li
                        key={sub.subject_id}
                        onClick={() => onSelectSubject(sub.subject_id)}
                        value={state.subjectId}
                        id={
                          sub.subject_id === state.subjectId ? "activeTab" : ""
                        }
                      >
                        <span>{sub.subject}</span>
                      </li>
                    );
                  })}
              </ul>
              <h5>My Saved Lessons</h5>
              <ul>
                <li>
                  <Link to="/create-profile">
                    <span>Update Profile</span>
                  </Link>
                </li>
                <li onClick={onLogout}>
                  <Link to="/">
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      {!state.showVideoPage && (
        <div className="contentColm" style={{ marginTop: 80 }}>
          <div className="row justify-content-end">
            <div style={{ float: "right" }}>
              <div className="form-group mb-0">
                <select
                  className="form-control"
                  id=""
                  onChange={onSelectChapter}
                  value={state.chapterId}
                >
                  {state.chapters && state.chapters.length > 0 ? (
                    state.chapters.map((chapter) => {
                      return (
                        <option
                          key={chapter.chapter_id}
                          value={chapter.chapter_id}
                        >
                          {chapter.chapter_name}
                        </option>
                      );
                    })
                  ) : (
                    <option>No chapters</option>
                  )}
                </select>
              </div>
            </div>
          </div>

          {state.videosDict && Object.keys(state.videosDict).length > 0 ? (
            Object.keys(state.videosDict).map((item) => {
              return (
                <React.Fragment>
                  <div className="row picDet" key={state.videosDict[item].id}>
                    {/* <div className="col-sm-4">
                      <div className="row"> */}
                    <div>
                      <img
                        src={"/images/teacher.png"}
                        alt={state.videosDict[item].name}
                        className="img-fluid"
                      />
                    </div>
                    <div className="eduDetail">
                      <h5>
                        {state.videosDict[item]?.name
                          ? state.videosDict[item].name
                          : "Shravan"}
                      </h5>
                      <h6>
                        {state.videosDict[item]?.details != null
                          ? state.videosDict[item].details
                          : "Educator"}
                      </h6>
                      {/* <div className="starsRating">
                            {[1, 2, 3, 4, 5].map((rating: number) => {
                              return (
                                <span
                                  className={`fa fa-star ${
                                    rating <= state.educators.ratings_count
                                      ? "checked"
                                      : ""
                                  }`}
                                ></span>
                              );
                            })}
                          </div> */}
                      {/* </div>
                      </div> */}
                    </div>
                  </div>
                  {state.videosDict[item].data &&
                    state.videosDict[item].data.length > 0 &&
                    state.videosDict[item].data.map(
                      (ele: IVideosDetails, index: number) => {
                        return (
                          <div className="vidoDetailClm">
                            <div
                              className="row"
                              onClick={() =>
                                showVideoPage(
                                  ele,
                                  state.videosDict[item].name,
                                  state.videosDict[item].image_url,
                                  item as any,
                                  index
                                )
                              }
                            >
                              <div className="col-sm-3">
                                <img
                                  src={
                                    ele?.thumbnail
                                      ? ele.thumbnail
                                      : "/images/thumb1.jpg"
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="col-sm-8">
                                <h5 className="text-bold">
                                  {ele?.video_title || "Default Title"}
                                </h5>
                                <div className="tagsLink">
                                  {student.boardName && (
                                    <p>{student.boardName}</p>
                                  )}
                                  {student.mediumName && (
                                    <p>{student.mediumName}</p>
                                  )}
                                  {student.className && (
                                    <p>Class {student.className}</p>
                                  )}
                                  {student.allSubjects &&
                                    student.allSubjects.length > 0 && (
                                      <p>
                                        {
                                          student.allSubjects.find(
                                            (sub) =>
                                              sub.subject_id ===
                                              parseInt(params.subjectId)
                                          )?.subject
                                        }
                                      </p>
                                    )}
                                  {state.chapters &&
                                    state.chapters.length > 0 && (
                                      <p>
                                        {
                                          state.chapters.find(
                                            (c) =>
                                              c.chapter_id === state.chapterId
                                          )?.chapter_name
                                        }
                                      </p>
                                    )}
                                </div>
                                <p>{ele.description}</p>
                                {/* <p className="pubDate">
                              Published on{" "}
                              <span className="publish_Date">02 Sept 2020</span>
                            </p> */}
                              </div>
                              {/* <div className="col-sm-1">
                                <img
                                  src={
                                    ele?.is_clapped
                                      ? "/images/applause_yellow.png"
                                      : "/images/applause_white.png"
                                  }
                                  className="img-fluid"
                                  alt=""
                                  style={{ width: 30 }}
                                />
                              </div> */}
                            </div>
                          </div>
                        );
                      }
                    )}
                </React.Fragment>
              );
            })
          ) : (
            <div style={{ marginTop: 200 }}>
              {/* <h5 style={{ textAlign: "center", marginTop: 200 }}>
              No Educator & Videos found for this combination
            </h5> */}
              <NoData msg="No lessons for this chapter" />
            </div>
          )}
        </div>
      )}
      {state.showVideoPage && (
        <ViewLesson
          videoObj={state.videoObj}
          educatorObj={state.educatorObj}
          handleShowVideoPage={handleShowVideoPage}
          subjects={student.allSubjects}
          subjectId={parseInt(params.subjectId)}
          chapters={state.chapters}
          chapterId={state.chapterId}
          className={student.className}
          mediumName={student.mediumName}
          boardName={student.boardName}
          savedLesson={false}
          savedALesson={savedALesson}
          clapedALesson={clapedALesson}
        />
      )}
      {/* </div> */}
    </React.Fragment>
  );
};

export default DashboardMain;
