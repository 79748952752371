import * as React from "react";
import { isTokenExpired, parseJwt } from "../../services/UtilService";
import { Link, withRouter } from "react-router-dom";
import "./navbar.css";

export interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = (props) => {
  // state
  const [state, setState] = React.useState({
    showStudentProfile: true,
  } as {
    showStudentProfile: boolean;
  });

  // decode the token
  const preference = parseJwt();
  console.log("nvbar");

  const showSidebar = () => {
    document.querySelector(".sideBar")?.classList.add("showSideBar");
    document.querySelector("#main_containerin")?.classList.add("ml-300px");
    setState({ ...state, showStudentProfile: !state.showStudentProfile });
  };

  const hideSidebar = () => {
    document.querySelector(".sideBar")?.classList.remove("showSideBar");
    document.querySelector("#main_containerin")?.classList.remove("ml-300px");
    setState({ ...state, showStudentProfile: !state.showStudentProfile });
  };

  const hasTokeExpired = isTokenExpired();
  return (
    <React.Fragment>
      {!hasTokeExpired && (
        <div id="head" className="header">
          <div className="row">
            <div className="col-7">
              {window.location.pathname !== "/profile" &&
                state.showStudentProfile && (
                  <div className="sideBarBtnClose" onClick={hideSidebar}>
                    <img
                      src="/images/less-than.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                )}
              {window.location.pathname !== "/profile" &&
                !state.showStudentProfile && (
                  <div className="sideBarBtn" onClick={showSidebar}>
                    <img
                      src="/images/icon-bar.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                )}
              <div className="logo">
                <Link to="/saved-lessons">
                  <img className="img-fluid" src="/images/logo.png" alt="" />
                </Link>
              </div>
            </div>
            <div className="col-5">
              {window.location.pathname !== "/profile" && (
                <div className="navi">
                  <div className="menu">
                    <ul>
                      {/* <li>
                          <Link to="">
                            Teacher Comments<span className="notifTip">5</span>
                          </Link>
                        </li> */}
                      <li className="profileNav">
                        {(preference?.profile_name &&
                          preference?.profile_name) ||
                          preference?.name}
                        <img
                          src={preference.image_url || "/images/student.png"}
                          alt=""
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(NavBar);
