import * as React from 'react';

export interface NoDataProps {
  msg?: string;
}

const NoData: React.FC<NoDataProps> = ({ msg }) => {
  return (
    <React.Fragment>
      <div className='text-center p-16'>
        <img
          src={'/images/empty2.png'}
          alt='noData'
          width='72px'
          height='72px'
          style={{ marginBottom: 12 }}
        />
        <h5>{msg || 'No Data found.'}</h5>
      </div>
    </React.Fragment>
  );
};

export default NoData;
