import React from "react";
import { Link } from "react-router-dom";
import {
  addClapLessons,
  addSavedLessons,
} from "../../services/DashboardServices";
import { parseJwt } from "../../services/UtilService";
import { IChapterDetails, IVideosDetails } from "../../vm";
import "../style.css";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import SavedLesson from "./SavedLesson";

export interface ViewLessonProps {
  videoObj: IVideosDetails;
  handleShowVideoPage: any;
  educatorObj?: any;
  chapterId?: number;
  chapters?: IChapterDetails[];
  boardName: string;
  mediumName: string;
  className: string;
  savedLesson: boolean;
  savedALesson?: Function;
  clapedALesson?: Function;
  subjects?: any;
  subjectId?: number;
}

const ViewLesson: React.FC<ViewLessonProps> = ({
  videoObj,
  handleShowVideoPage,
  educatorObj,
  chapters,
  chapterId,
  className,
  savedLesson,
  savedALesson,
  clapedALesson,
  boardName,
  mediumName,
  subjects,
  subjectId,
}) => {
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    is_saved: videoObj.is_saved,
    is_clapped: videoObj.is_clapped,
  } as {
    isLoading: boolean;
    is_saved: boolean;
    is_clapped: boolean;
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  const onSavedALesson = async () => {
    setState({ ...state, isLoading: true });
    const preference = parseJwt();
    let result = await addSavedLessons({
      userID: preference.id,
      lessonID: videoObj.lesson_id,
    });
    if (result && result.status) {
      setState({
        ...state,
        is_saved: true,
        isLoading: false,
      });
      savedALesson();
      showToast("Lesson saved successfully", "success");
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while saving lesson", "error");
    }
  };

  const onClapedALesson = async () => {
    setState({ ...state, isLoading: true });
    let result = await addClapLessons({
      lessonID: videoObj.lesson_id,
    });
    if (result && result.status) {
      setState({
        ...state,
        is_clapped: true,
        isLoading: false,
      });
      clapedALesson();
      showToast("Thanks for clapping!", "success");
    } else {
      setState({ ...state, isLoading: false });
      showToast(result?.message || "Error while clapping lesson", "error");
    }
  };

  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      <div className="contentColm" style={{ marginTop: 80 }}>
        <button onClick={handleShowVideoPage} className="backtBtn">
          Back
        </button>
        <div className="lessonClm">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src={`https://player.vimeo.com/video/${videoObj.video_url}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="d-flex justify-space-between align-items-center">
            <h5 className="text-bold">
              <Link to={`https://vimeo.com/${videoObj.video_url}`}>
                {videoObj?.video_title || ""}
                {/* {videoObj?.video_title ? (
                  <h5>{videoObj.video_title}</h5>
                ) : (
                  "Default Title"
                )} */}
                {/* Class {className} {chapters && "- "}
                {chapters &&
                  chapters.find((c) => c.chapter_id === chapterId)
                    ?.chapter_name}{" "} */}
                {/* {educatorObj?.educatorName && "- "}{" "}
                {educatorObj && educatorObj.educatorName} */}
              </Link>
            </h5>
            <div className="lesson-actions-top">
              {/* {savedLesson ? (
                <span style={{ color: '#dcb100', fontSize: 30, padding: 16 }}>
                  <i className='fa fa-bookmark'></i>
                </span>
              ) : ( */}
              {!savedLesson && (
                <React.Fragment>
                  <span
                    style={{
                      color: "#dcb100",
                      fontSize: 30,
                      cursor: "pointer",
                      padding: 16,
                    }}
                    onClick={onSavedALesson}
                  >
                    <i
                      className={
                        state.is_saved ? "fa fa-bookmark" : "fa fa-bookmark-o"
                      }
                    ></i>
                  </span>
                  <span onClick={onClapedALesson} style={{ cursor: "pointer" }}>
                    <img
                      src={
                        state?.is_clapped
                          ? "/images/applause_yellow.png"
                          : "/images/applause_white.png"
                      }
                      className="img-fluid"
                      alt=""
                      style={{ width: 35, margin: 16 }}
                    />
                  </span>
                </React.Fragment>
              )}
              {/* )} */}
            </div>
          </div>
          <div className="tagsLink-bold">
            {boardName && <p>{boardName}</p>}
            {mediumName && <p>{mediumName}</p>}
            {className && <p>Class {className}</p>}
            {subjects && (
              <p>
                {subjects.find((s: any) => s.subject_id === subjectId)?.subject}
              </p>
            )}
            {chapters && (
              <p>
                {chapters.find((c) => c.chapter_id === chapterId)?.chapter_name}
              </p>
            )}
          </div>
        </div>
        <div className="row p-16">
          <div className="col-sm-12 post_detail">
            {educatorObj && (
              <h5 className="teacher_profile">
                <img src={"/images/teacher.png"} alt="" />
                {"   "}
                {educatorObj?.educatorName
                  ? educatorObj.educatorName
                  : " Shravan"}
              </h5>
            )}
            <hr />
          </div>
          <div style={{ marginTop: -15 }}>
            <p>{videoObj?.description}</p>
          </div>
        </div>
        <div className="row p-16" style={{ marginTop: -30 }}>
          <div className="lesson-actions-bottom">
            {/* {savedLesson ? (
              <span style={{ color: '#dcb100', fontSize: 30, padding: 16 }}>
                <i className='fa fa-bookmark'></i>
              </span>
            ) : ( */}
            {!savedLesson && (
              <React.Fragment>
                <span
                  style={{
                    color: "#dcb100",
                    fontSize: 30,
                    cursor: "pointer",
                    padding: 16,
                  }}
                  onClick={onSavedALesson}
                >
                  <i
                    className={
                      state.is_saved ? "fa fa-bookmark" : "fa fa-bookmark-o"
                    }
                  ></i>
                </span>
                <span onClick={onClapedALesson} style={{ cursor: "pointer" }}>
                  <img
                    src={
                      state?.is_clapped
                        ? "/images/applause_yellow.png"
                        : "/images/applause_white.png"
                    }
                    className="img-fluid"
                    alt=""
                    style={{ width: 35, margin: 16 }}
                  />
                </span>
              </React.Fragment>
            )}
            {/* )} */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewLesson;
