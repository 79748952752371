import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getAllSubjects } from "../../services/DashboardServices";
import {
  getAllBoards,
  getAllClasses,
  getAllMediums,
} from "../../services/ProfileServices";
import {
  isTokenExpired,
  parseJwt,
  removeToken,
} from "../../services/UtilService";
import {
  IBoardDetails,
  IClassDetails,
  IMediumDetails,
  ISubjectDetails,
} from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import "../style.css";
import { ProfileContext } from "../common/ProfileProvider";

export interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  const location = useLocation();
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    allBoards: [],
    allClasses: [],
    allMediums: [],
    allSubjects: [],
    subjectId: undefined,
    selectedItem: location.pathname,
  } as {
    isLoading: boolean;
    allBoards: Array<{ board_id: number; board_name: string }>;
    allMediums: Array<{ medium_id: number; medium: string }>;
    allClasses: Array<{ class_id: number; class_name: string }>;
    allSubjects: ISubjectDetails[];
    subjectId: number;
    selectedItem: string;
  });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { preference } = React.useContext(ProfileContext);
  console.log("pre provider", preference);
  const history = useHistory();

  // token
  const token = parseJwt();
  console.log("token", token);

  // console.log("side bar render out side ");
  console.log("isTokenExpired", isTokenExpired());

  // React.useEffect(() => {
  //   if (!isTokenExpired()) {
  //     const asyncFunc = async () => {
  //       await getSubjects();
  //       console.log("CALLED");
  //     };
  //     asyncFunc();
  //   }
  // }, [preference?.class_id, preference?.board_id, preference?.medium_id]);

  if (state.selectedItem !== location.pathname) {
    setState({ ...state, selectedItem: location.pathname });
  }

  // hooks
  React.useEffect(() => {
    if (!isTokenExpired()) {
      const asyncFunc = async () => {
        await getClassDetails();
        console.log("sidebar inside useeffect");
      };
      asyncFunc();
    }
  }, [preference?.class_id, preference?.board_id, preference?.medium_id]);

  const getClassDetails = async () => {
    setState({ ...state, isLoading: true });
    let result = await getAllClasses();
    if (result && result.status) {
      getMediumDetails(result.data);
    } else {
      setState({ ...state, isLoading: false });
      // showToast(
      //   result?.message || "Error while getting class details",
      //   "error"
      // );
    }
  };

  const getMediumDetails = async (allClasses: any) => {
    setState({ ...state, isLoading: true });
    let result = await getAllMediums();
    if (result && result.status) {
      getBoardDetails(allClasses, result.data.getMedium);
    } else {
      setState({ ...state, isLoading: false });
      // showToast(
      //   result?.message || "Error while getting medium details",
      //   "error"
      // );
    }
  };

  const getBoardDetails = async (allClasses: any, allMediums: any) => {
    setState({ ...state, isLoading: true });

    // const token = parseJwt();
    // console.log("pn", token);

    let result = await getAllBoards();
    if (result && result.status) {
      let obj = {
        ...state,
        allBoards: result.data.getBoard,
        allClasses,
        allMediums,
        isLoading: false,
      };
      setState(obj);
      getSubjects(obj);
    } else {
      setState({ ...state, isLoading: false });
      // showToast(
      //   result?.message || "Error while getting board details",
      //   "error"
      // );
    }
  };

  const getSubjects = async (paramState?: any) => {
    let localState = { ...state };
    if (paramState != null) {
      localState = { ...paramState };
    }
    setState({ ...localState, isLoading: true });

    // const token = parseJwt();
    // console.log("p", token);

    let result = await getAllSubjects({
      board_id: token?.board_id,
      class_id: token?.class_id,
      medium_id: token?.medium_id,
      // board_id: 2,
      // class_id: 1,
      // medium_id: 1,
    });
    if (result && result.status) {
      setState({
        ...localState,
        allSubjects: result.data,
        subjectId: result.data[0]?.subject_id,
        selectedItem: location.pathname,
        isLoading: false,
      });
    } else {
      setState({
        ...localState,
        isLoading: false,
      });
      // showToast(result?.message || "Error while getting subject list", "error");
    }
  };

  const onSelectSubject = (subjectId: number) => {
    history.push(`/subject/${subjectId}/lessons`);
  };

  const onLogout = () => {
    document.querySelector(".sideBar")?.classList.remove("showSideBar");
    document.querySelector("#main_containerin")?.classList.remove("ml-300px");
    removeToken();
  };

  const onUpdateProfile = () => {
    document.querySelector(".sideBar")?.classList.remove("showSideBar");
    document.querySelector("#main_containerin")?.classList.remove("ml-300px");
  };

  const onSelectTab = (endUrl: string) => {
    setState({ ...state, selectedItem: endUrl });
  };

  return (
    <React.Fragment>
      {state.isLoading && <Loading />}
      {token && (
        <div className="sideBar">
          <div className="profileDetail">
            <div className="picDet">
              <img
                src={token?.imageUrl ? token.imageUrl : "/images/student.png"}
                alt=""
                className="img-fluid"
              />
              <h5>
                {(token?.profile_name && token.profile_name) || token.name}
              </h5>
              <h6>
                Class{" "}
                {state.allClasses.find((c) => c.class_id === token.class_id)
                  ?.class_name || 1}{" "}
                |{" "}
                {
                  state.allBoards.find((b) => b.board_id === token.board_id)
                    ?.board_name
                }
              </h6>
              <h6>
                {
                  state.allMediums.find((m) => m.medium_id === token.medium_id)
                    ?.medium
                }{" "}
                Medium
              </h6>
            </div>

            <div className="stdntDetails">
              <h5>My Subjects</h5>
              <ul>
                {state.allSubjects &&
                  state.allSubjects?.length > 0 &&
                  state.allSubjects.map((sub) => {
                    return (
                      <li
                        key={sub.subject_id}
                        onClick={() => {
                          onSelectSubject(sub.subject_id);
                          onSelectTab(`/subject/${sub.subject_id}/lessons`);
                        }}
                        value={state.subjectId}
                        // id={
                        //   sub.subject_id === state.subjectId ? "activeTab" : ""
                        // }
                      >
                        <span
                          className={
                            state.selectedItem ===
                            `/subject/${sub.subject_id}/lessons`
                              ? "selectedTab"
                              : ""
                          }
                        >
                          {sub.subject}
                        </span>
                      </li>
                    );
                  })}
              </ul>
              {/* <span
                onClick={() => history.push("/saved-lessons")}
                style={{ cursor: "pointer" }}
              >
                <h5>My Saved Lessons</h5>
              </span> */}
              <ul>
                <li onClick={() => onSelectTab("/saved-lessons")}>
                  <Link to="/saved-lessons">
                    <span
                      className={
                        state.selectedItem === "/saved-lessons"
                          ? "selectedTab"
                          : ""
                      }
                    >
                      My Saved Lessons
                    </span>
                  </Link>
                </li>
                <li onClick={onUpdateProfile}>
                  <Link to="/profile">
                    <span>Update Profile</span>
                  </Link>
                </li>
                <li onClick={onLogout}>
                  <Link to="/">
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
