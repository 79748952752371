// old dev
// export const API_URL = "http://167.71.230.254/gurukul/public/api/";

// local
// export const API_URL =
//   "http://localhost:9000/http://167.71.230.254/gurukul/public/api/";

// new dev
export const API_URL = "https://api.starguru.org/api/";

export const LANGUAGES_DICT: { [Key: string]: string } = {
  "1": "English",
  "2": "తెలుగు",
  "3": "اردو",
  "4": "हिन्दी",
  "5": "ಕನ್ನಡ",
  "6": "বাংলা",
  "7": "தமிழ்",
  "8": "मराठी",
};
