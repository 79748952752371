import * as React from "react";
import "./loading.css";
const Loading = (props) => {
  return (
    <React.Fragment>
      <div id="myModal" className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <h4 className="loading-text">
            {props.loadingText || "Please wait..."}
          </h4>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Loading;
