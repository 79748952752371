import React from "react";
import { parseJwt } from "../../services/UtilService";

interface IDispatch {}

interface dispatchFunc {
  (params: IDispatch): void;
}

interface IState {
  preference: any;
}

interface IProfile {
  preference: any;
  functionToUpdate: Function;
}

export const ProfileContext = React.createContext<any>({
  preference: {},
  functionToUpdate: () => {},
} as any);

export const ProfileProvider: React.FC<any> = (props) => {
  const token = parseJwt();
  const [state, setState] = React.useState({
    preference: token,
  });

  //   React.useLayoutEffect(() => {
  //     // const preference = parseJwt();
  //     // setState({ ...preference });
  //   }, []);

  const profileContext = React.useMemo(
    () => ({
      functionToUpdate: (token: any) => {
        if (token) {
          const preference = parseJwt(token);
          setState({ preference });
        }
      },
    }),
    []
  );
  return (
    <ProfileContext.Provider value={{ ...state, ...profileContext }}>
      {props.children}
    </ProfileContext.Provider>
  );
};
