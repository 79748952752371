import * as React from 'react';
import { Formik, FormikErrors, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import { ProfileContext } from '../common/ProfileProvider';

import './login.css';
import { ILogin, ISignup } from '../../vm';
import { loginToApp, signupToApp } from '../../services/LoginService';
import {
  isTokenExpired,
  isValidEmail,
  parseJwt,
  setProfile,
  setToken,
} from '../../services/UtilService';
import { ToastContext } from '../common/ToastProvider';
import { getAllSubjects } from '../../services/DashboardServices';
import Loading from '../common/Loading';
export interface LoginMainProps {}

const LoginMain: React.FC<LoginMainProps> = (props) => {
  const history = useHistory();
  const { showToast } = React.useContext(ToastContext);
  const { functionToUpdate } = React.useContext(ProfileContext);
  const [formShown, setFormShown] = React.useState(
    'login' as 'login' | 'register'
  );
  const [loadingState, setLoadingState] = React.useState({
    isLoading: false,
    loadingText: '',
  });
  if (!isTokenExpired()) {
    history.push('/saved-lessons');
  }

  return (
    <React.Fragment>
      {loadingState.isLoading && (
        <Loading loadingText={loadingState.loadingText} />
      )}
      <div className='login-container'>
        <div className='bg-watermark-container'>
          <div
            className='bg-watermark'
            style={{
              backgroundImage: 'url(/images/login/Login-watermark.png)',
            }}></div>
        </div>
        <div className='left-section'>
          <div className='login-form'>
            <div>
              <img className='login-logo' src='/images/logo.png' alt='' />

              <br />
              <br />
              <p className='p-margin'>
                <label
                  className={`${
                    formShown === 'login' ? 'activeTab' : ''
                  } signInLink`}
                  onClick={() => setFormShown('login')}>
                  Sign in
                </label>{' '}
                <label
                  className={`${
                    formShown === 'register' ? 'activeTab' : ''
                  } signUpLink`}
                  onClick={() => setFormShown('register')}>
                  Sign up
                </label>
              </p>
              {formShown === 'login' ? (
                <div className='login-form-container'>
                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    validate={(values) => {
                      let errors: FormikErrors<ILogin> = {};
                      if (!values.email) {
                        errors.email = 'Required';
                      } else if (!isValidEmail(values.email)) {
                        errors.email = 'Please enter valid email';
                      }
                      if (!values.password) {
                        errors.password = 'Required';
                      } else if (values.password.length <= 4) {
                        errors.password = 'Needs to be more than 4 characters';
                      }
                      return errors;
                    }}
                    onSubmit={async (values: ILogin, { setSubmitting }) => {
                      setLoadingState({
                        isLoading: true,
                        loadingText: 'Loading... Please wait',
                      });
                      let result = await loginToApp(values);
                      setLoadingState({
                        isLoading: false,
                        loadingText: 'Loading... Please wait',
                      });
                      if (result) {
                        if (result && result.status) {
                          setToken(result.data.token);
                          setProfile(result.data.profile);
                          functionToUpdate(result.data.token);
                          const preferences = parseJwt(result.data.token);
                          console.log('pr', preferences);

                          if (
                            preferences.board_id &&
                            preferences.class_id &&
                            preferences.medium_id
                          ) {
                            setLoadingState({
                              isLoading: true,
                              loadingText: 'Loading... Please wait',
                            });
                            let res = await getAllSubjects({
                              board_id: preferences.board_id,
                              class_id: preferences.class_id,
                              medium_id: preferences.medium_id,
                            });
                            setLoadingState({
                              isLoading: false,
                              loadingText: 'Loading... Please wait',
                            });
                            if (res && res.status && res.data.length > 0) {
                              history.push(
                                `/subject/${res.data[0]?.subject_id}/lessons`
                              );
                            } else {
                              history.push('/saved-lessons');
                            }
                          } else {
                            history.push('/profile');
                          }
                        } else {
                          showToast(result.message, 'error');
                        }
                      }
                      setSubmitting(false);
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <React.Fragment>
                          <div className='form-group'>
                            <label htmlFor='email'>Email address</label>
                            <input
                              type='email'
                              name='email'
                              value={values.email}
                              className='form-control'
                              id='email'
                              aria-describedby='emailHelp'
                              placeholder='Enter email'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // required
                            />
                            {(errors.email && touched.email && errors.email) ||
                              ''}
                          </div>
                          <div className='form-group'>
                            <label htmlFor='password'>Password</label>
                            <input
                              type='password'
                              name='password'
                              value={values.password}
                              className='form-control'
                              id='password'
                              placeholder='Password'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // required
                            />
                            {(errors.password &&
                              touched.password &&
                              errors.password) ||
                              ''}
                          </div>
                          <br />
                          <div className='text-center'>
                            <button type='submit' id='sigInBtn' className='btn'>
                              Sign in
                            </button>
                          </div>
                        </React.Fragment>
                      </form>
                    )}
                  </Formik>
                </div>
              ) : (
                <div className='login-form-container'>
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      password: '',
                      password_confirmation: '',
                    }}
                    validate={(values) => {
                      let errors: FormikErrors<ISignup> = {};
                      if (!values.name) {
                        errors.name = 'Required';
                      }
                      if (!values.email) {
                        errors.email = 'Required';
                      } else if (!isValidEmail(values.email)) {
                        errors.email = 'Please enter valid email';
                      }
                      if (!values.password) {
                        errors.password = 'Required';
                      } else if (values.password.length <= 4) {
                        errors.password = 'Needs to be more than 4 characters';
                      }
                      if (!values.password_confirmation) {
                        errors.password_confirmation = 'Required';
                      } else if (
                        values.password_confirmation !== values.password
                      ) {
                        errors.password_confirmation =
                          "Password & Repeat Password doesn't match";
                      }
                      return errors;
                    }}
                    onSubmit={async (values: ISignup, { setSubmitting }) => {
                      setLoadingState({
                        isLoading: true,
                        loadingText: 'Loading... Please wait',
                      });
                      let result = await signupToApp(values);
                      setLoadingState({
                        isLoading: false,
                        loadingText: 'Loading... Please wait',
                      });
                      if (result) {
                        if (result && result.status) {
                          setToken(result.data?.token);
                          // setProfile(result.data.profile);
                          history.push('/profile');
                        } else {
                          showToast(result.message, 'error');
                        }
                      }
                      setSubmitting(false);
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <React.Fragment>
                          <div className='form-group'>
                            <label htmlFor='name'>Full Name</label>
                            <input
                              type='text'
                              name='name'
                              value={values.name}
                              className='form-control'
                              id='name'
                              aria-describedby='nameHelp'
                              placeholder='Enter Full Name'
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {(errors.name && touched.name && errors.name) || ''}
                          </div>
                          <div className='form-group'>
                            <label htmlFor='email'>Email address</label>
                            <input
                              type='email'
                              name='email'
                              value={values.email}
                              className='form-control'
                              id='email'
                              aria-describedby='emailHelp'
                              placeholder='Enter email'
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {(errors.email && touched.email && errors.email) ||
                              ''}
                          </div>
                          <div className='form-group'>
                            <label htmlFor='password'>Password</label>
                            <input
                              type='password'
                              name='password'
                              value={values.password}
                              className='form-control'
                              id='password'
                              placeholder='Password'
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {(errors.password &&
                              touched.password &&
                              errors.password) ||
                              ''}
                          </div>
                          <div className='form-group'>
                            <label htmlFor='confirm-password'>
                              Repeat Password
                            </label>
                            <input
                              type='password'
                              name='password_confirmation'
                              value={values.password_confirmation}
                              className='form-control'
                              id='password_confirmation'
                              placeholder='Repeat Password'
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {(errors.password_confirmation &&
                              touched.password_confirmation &&
                              errors.password_confirmation) ||
                              ''}
                          </div>
                          <br />
                          <div className='text-center'>
                            <button type='submit' id='sigInBtn' className='btn'>
                              Sign up
                            </button>
                          </div>
                        </React.Fragment>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='right-section'>
          <div className='right-img-container'>
            <img
              src='/images/StudentLoginAsset.png'
              alt=''
              className='right-image'
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginMain;
