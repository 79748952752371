import React from "react";
import {
  createStudentProfile,
  getAllBoards,
  getAllClasses,
  getAllMediums,
  updateStudentProfile,
} from "../../services/ProfileServices";
import { IStudentProfile } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { ProfileContext } from "../common/ProfileProvider";
import { useHistory } from "react-router-dom";
import "../style.css";
import { parseJwt, setToken } from "../../services/UtilService";
import Loading from "../common/Loading";
import { LANGUAGES_DICT } from "../../Constant";
import { getAllSubjects } from "../../services/DashboardServices";

export interface WelcomeMainProps {}

const WelcomeMain: React.FC<WelcomeMainProps> = () => {
  // state
  const [state, setState] = React.useState({
    isLoading: false,
    profile: {
      name: "",
      board_id: undefined,
      class_id: undefined,
      medium_id: undefined,
      languages: [],
    },
    imageUrl: "",
    showWelcomeSec: true,
    showBoardSec: false,
    showClassSec: false,
    showMediumSec: false,
    showLanguagesSec: false,
    allBoards: [],
    allClasses: [],
    allMediums: [],
  } as {
    isLoading: boolean;
    profile: IStudentProfile;
    imageUrl: string;
    showWelcomeSec: boolean;
    showBoardSec: boolean;
    showClassSec: boolean;
    showMediumSec: boolean;
    showLanguagesSec: boolean;
    allBoards: Array<{ board_id: number; board_name: string }>;
    allClasses: Array<{ class_id: number; class_name: string }>;
    allMediums: Array<{ medium_id: number; medium: string }>;
  });

  // const [subject, setSubject] = React.useState({
  //   isLoading: false,
  //   subject_id: undefined,
  // } as {
  //   isLoading: boolean;
  //   subject_id: number;
  // });

  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { functionToUpdate } = React.useContext(ProfileContext);
  const history = useHistory();

  // hooks
  React.useEffect(() => {
    const asyncFunc = async () => {
      await getBoardDetails();
      // await getSubjects();
    };
    asyncFunc();
  }, []);

  const getBoardDetails = async () => {
    setState({ ...state, isLoading: true });
    let result = await getAllBoards();
    if (result && result.status) {
      getClassDetails(result.data.getBoard);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting board details",
        "error"
      );
    }
  };

  const getClassDetails = async (allBoards: any) => {
    setState({ ...state, isLoading: true });
    let result = await getAllClasses();
    if (result && result.status) {
      getMediumDetails(allBoards, result.data);
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting class details",
        "error"
      );
    }
  };

  const getMediumDetails = async (allBoards: any, allClasses: any) => {
    setState({ ...state, isLoading: true });

    const preferences = parseJwt();

    // console.log(studentObj);

    let result = await getAllMediums();
    if (result && result.status) {
      setState({
        ...state,
        allBoards,
        allClasses,
        allMediums: result.data.getMedium,
        profile: {
          ...state.profile,
          name:
            (preferences?.profile_name && preferences.profile_name) ||
            preferences?.name,
          board_id:
            (preferences?.board_id && preferences.board_id) || undefined,
          class_id:
            (preferences?.class_id && preferences.class_id) || undefined,
          medium_id:
            (preferences?.medium_id && preferences.medium_id) || undefined,
          languages: (preferences?.languages && preferences.languages) || [],
        },
        imageUrl: preferences?.image_url,
        showWelcomeSec:
          preferences?.board_id && preferences.class_id && preferences.medium_id
            ? false
            : true,
        showBoardSec:
          preferences?.board_id && preferences.class_id && preferences.medium_id
            ? true
            : false,
      });
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message || "Error whiling getting medium details",
        "error"
      );
    }
  };

  // const getSubjects = async () => {
  //   setSubject({ ...subject, isLoading: true });

  //   const preferences = parseJwt();

  //   let result = await getAllSubjects({
  //     board_id: preferences.board_id,
  //     class_id: preferences.class_id,
  //     medium_id: preferences.medium_id,
  //     // board_id: 2,
  //     // class_id: 1,
  //     // medium_id: 1,
  //   });
  //   if (result && result.status) {
  //     setSubject({
  //       ...subject,
  //       subject_id: result.data[0]?.subject_id,
  //       isLoading: false,
  //     });
  //   } else {
  //     setSubject({
  //       ...subject,
  //       isLoading: false,
  //     });
  //     // showToast(result?.message || "Error while getting subject list", "error");
  //   }
  // };

  const onSelectBoard = (board_id: number) => {
    setState({
      ...state,
      showBoardSec: false,
      showClassSec: true,
      profile: {
        ...state.profile,
        board_id,
      },
    });
  };

  const onSelectClass = (class_id: number) => {
    setState({
      ...state,
      showClassSec: false,
      showMediumSec: true,
      profile: {
        ...state.profile,
        class_id,
      },
    });
  };

  const onSelectMedium = (medium_id: number) => {
    setState({
      ...state,
      showMediumSec: false,
      showLanguagesSec: true,
      profile: {
        ...state.profile,
        medium_id,
      },
    });
  };

  const onSelectLanguages = (e: any) => {
    let languages = [...state.profile.languages];
    if (languages.includes(e.target.value) === false) {
      languages.push(e.target.value);
    } else {
      let index = languages.findIndex((l) => l === e.target.value);
      languages.splice(index, 1);
    }
    setState({
      ...state,
      profile: {
        ...state.profile,
        languages,
      },
    });
    console.log("state", state.profile);
  };

  const onSubmitProfile = async () => {
    setState({ ...state, isLoading: true });
    const preferences = parseJwt();
    let result: any;
    if (
      preferences?.board_id &&
      preferences?.class_id &&
      preferences?.medium_id
    ) {
      result = await updateStudentProfile(state.profile);
    } else {
      result = await createStudentProfile(state.profile);
    }
    if (result && result.status) {
      setState({
        ...state,
        profile: {
          ...state.profile,
          name: "",
          class_id: undefined,
          board_id: undefined,
          medium_id: undefined,
          languages: [],
        },
        imageUrl: "",
        showWelcomeSec: true,
        showBoardSec: false,
        showClassSec: false,
        showMediumSec: false,
        showLanguagesSec: false,
        isLoading: false,
      });
      setToken(result.data.token);
      functionToUpdate(result.data.token);
      showToast(
        `Profile ${
          preferences.board_id && preferences.class_id && preferences.medium_id
            ? "updated"
            : "created"
        } successfully`,
        "success"
      );
      // if (subject.subject_id === undefined) {
      //   history.push("/saved-lessons");
      // } else {
      //   history.push(`/subject/${subject.subject_id}/lessons`);
      // }
      history.push("/saved-lessons");
    } else {
      setState({ ...state, isLoading: false });
      showToast(
        result?.message ||
          `Error while ${
            preferences.board_id &&
            preferences.class_id &&
            preferences.medium_id
              ? "updating"
              : "creating"
          } profile`,
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      {state.isLoading && <Loading text="Loading, please wait..." />}
      <div className="bodyColm" style={{ background: "#333333" }}>
        <br />
        <br />
        <div className="container">
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <form id="enqfom" action="">
                {state.showWelcomeSec &&
                  !state.profile.board_id &&
                  !state.profile.class_id &&
                  !state.profile.medium_id && (
                    <div id="session1" className="Stdt_Form">
                      <div className="row">
                        <div
                          className="col-sm-4 bg_Img"
                          // style={{
                          //   backgroundImage: 'url(/images/home_Student.jpg)',
                          // }}
                        >
                          {/* <div className='divHigh'></div> */}
                          <img src="/images/StudentProfile.png" alt="profile" />
                        </div>
                        <div className="col-sm-8">
                          <div
                            className="divHigh"
                            style={{
                              paddingRight: 50,
                              paddingLeft: 50,
                              paddingTop: 20,
                              paddingBottom: 20,
                              textAlign: "center",
                            }}
                          >
                            <h4>Setup your profile to start learning</h4>
                            <img
                              className="img-fluid"
                              src="/images/welcom.jpg"
                              alt=""
                            />
                            <div className="contmid">
                              <button
                                id="startsession"
                                className="startBtn"
                                onClick={() =>
                                  setState({
                                    ...state,
                                    showWelcomeSec: false,
                                    showBoardSec: true,
                                  })
                                }
                              >
                                Start
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {state.showBoardSec && (
                  <div id="session2" className="Stdt_Form">
                    <div className="row">
                      <div
                        className="col-sm-4 bg_Img"
                        style={{ backgroundColor: "#ffcc01" }}
                      >
                        <div className="divHigh">
                          <div className="left_Colm">
                            <div className="proPic">
                              <img
                                src={state.imageUrl || "/images/student.png"}
                                alt=""
                                className="img-fluid"
                              />
                              <h5>{state.profile.name}</h5>
                            </div>
                            <div className="sequenColm">
                              <ul>
                                <li>
                                  <span className="active_crcl">1</span> Board
                                </li>
                                <li>
                                  <span>2</span> Class
                                </li>
                                <li>
                                  <span>3</span> Medium
                                </li>
                                <li>
                                  <span>4</span> Languages
                                </li>
                              </ul>
                            </div>
                            <p>
                              At Starguru, we understand that everyone learns
                              differently. That is why we bring together
                              immersive video lessons for primary and secondary
                              school students in India created by progressive
                              teachers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="divHigh">
                          <div className="right_Colm">
                            <h4>Select your board </h4>
                            <hr />
                            <div className="board_Colm">
                              {state.allBoards && state.allBoards.length > 0 ? (
                                state.allBoards.map((board) => {
                                  return (
                                    <div
                                      className={"form_Cont"}
                                      key={board.board_id}
                                      onClick={() =>
                                        onSelectBoard(board.board_id)
                                      }
                                    >
                                      <label className="slctBox">
                                        <img src="/images/1.png" alt="" />
                                        <input
                                          className="boardCheck"
                                          type="radio"
                                          // name="board"
                                          value={board.board_id}
                                          checked={
                                            board.board_id ===
                                            state.profile.board_id
                                          }
                                          // disabled={board.board_name === "CBSE"}
                                          // onChange={onSelectBoard}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                      <p>{board.board_name}</p>
                                    </div>
                                  );
                                })
                              ) : (
                                <h4>No boards found</h4>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.showClassSec && (
                  <div id="session3" className="Stdt_Form">
                    <div className="row">
                      <div
                        className="col-sm-4 bg_Img"
                        style={{ backgroundColor: "#ffcc01" }}
                      >
                        <div className="divHigh">
                          <div className="left_Colm">
                            <div className="proPic">
                              <img
                                src={state.imageUrl || "/images/student.png"}
                                alt=""
                                className="img-fluid"
                              />
                              <h5>{state.profile.name}</h5>
                            </div>
                            <div className="sequenColm">
                              <ul>
                                <li>
                                  <span className="active_crcl_comp">
                                    <i className="fas fa-check"></i>
                                  </span>
                                  Board
                                </li>
                                <li>
                                  <span className="active_crcl">2</span>
                                  Class
                                </li>
                                <li>
                                  <span>3</span> Medium
                                </li>
                                <li>
                                  <span>4</span> Languages
                                </li>
                              </ul>
                            </div>
                            <p>
                              At Starguru, we understand that everyone learns
                              differently. That is why we bring together
                              immersive video lessons for primary and secondary
                              school students in India created by progressive
                              teachers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="divHigh">
                          <div className="right_Colm">
                            <h4>Select your class</h4>
                            <hr />
                            <div className="class_Colm">
                              {state.allClasses &&
                              state.allClasses.length > 0 ? (
                                state.allClasses.map((cls) => {
                                  return (
                                    <div
                                      className={"class_Form_Cont"}
                                      key={cls.class_id}
                                      onClick={() =>
                                        onSelectClass(cls.class_id)
                                      }
                                    >
                                      <label className="classlctBox">
                                        {"Class " + cls.class_name}
                                        <input
                                          className="class_Check"
                                          type="radio"
                                          // name="class"
                                          value={cls.class_id}
                                          checked={
                                            cls.class_id ===
                                            state.profile.class_id
                                          }
                                          // disabled={cls.class_name === "1"}
                                          // onChange={() =>
                                          //   onSelectClass(cls.class_id)
                                          // }
                                        />
                                        <span className="class_check"></span>
                                      </label>
                                    </div>
                                  );
                                })
                              ) : (
                                <h4>No classes found</h4>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.showMediumSec && (
                  <div id="session4" className="Stdt_Form">
                    <div className="row">
                      <div
                        className="col-sm-4 bg_Img"
                        style={{ backgroundColor: "#ffcc01" }}
                      >
                        <div className="divHigh">
                          <div className="left_Colm">
                            <div className="proPic">
                              <img
                                src={state.imageUrl || "/images/student.png"}
                                alt=""
                                className="img-fluid"
                              />
                              <h5>{state.profile.name}</h5>
                            </div>
                            <div className="sequenColm">
                              <ul>
                                <li>
                                  <span className="active_crcl_comp">
                                    <i className="fas fa-check"></i>
                                  </span>{" "}
                                  Board
                                </li>
                                <li>
                                  <span className="active_crcl_comp">
                                    <i className="fas fa-check"></i>
                                  </span>
                                  Class
                                </li>
                                <li>
                                  <span className="active_crcl">3</span> Medium
                                </li>
                                <li>
                                  <span>4</span> Languages
                                </li>
                              </ul>
                            </div>
                            <p>
                              At Starguru, we understand that everyone learns
                              differently. That is why we bring together
                              immersive video lessons for primary and secondary
                              school students in India created by progressive
                              teachers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="divHigh">
                          <div className="right_Colm">
                            <h4>Select your medium of instruction</h4>
                            <hr />
                            <div className="class_Colm">
                              {state.allMediums &&
                              state.allMediums.length > 0 ? (
                                state.allMediums.map((medium) => {
                                  return (
                                    <div
                                      className="class_Form_Cont"
                                      key={medium.medium_id}
                                      onClick={() =>
                                        onSelectMedium(medium.medium_id)
                                      }
                                    >
                                      <label className="classlctBox">
                                        {medium.medium}
                                        <input
                                          className="medium_Check"
                                          type="radio"
                                          name="medium"
                                          value={medium.medium_id}
                                          checked={
                                            medium.medium_id ===
                                            state.profile.medium_id
                                          }
                                          // onChange={onSelectMedium}
                                        />
                                        <span className="class_check"></span>
                                      </label>
                                    </div>
                                  );
                                })
                              ) : (
                                <h4>No mediums found</h4>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.showLanguagesSec && (
                  <div id="session5" className="Stdt_Form">
                    <div className="row">
                      <div
                        className="col-sm-4 bg_Img"
                        style={{ backgroundColor: "#ffcc01" }}
                      >
                        <div className="divHigh">
                          <div className="left_Colm">
                            <div className="proPic">
                              <img
                                src={state.imageUrl || "/images/student.png"}
                                alt=""
                                className="img-fluid"
                              />
                              <h5>{state.profile.name}</h5>
                            </div>
                            <div className="sequenColm">
                              <ul>
                                <li>
                                  <span className="active_crcl_comp">
                                    <i className="fas fa-check"></i>
                                  </span>{" "}
                                  Board
                                </li>
                                <li>
                                  <span className="active_crcl_comp">
                                    <i className="fas fa-check"></i>
                                  </span>{" "}
                                  Class
                                </li>
                                <li>
                                  <span className="active_crcl_comp">
                                    <i className="fas fa-check"></i>
                                  </span>{" "}
                                  Medium
                                </li>
                                <li>
                                  <span className="active_crcl">4</span>{" "}
                                  Languages
                                </li>
                              </ul>
                            </div>
                            <p>
                              At Starguru, we understand that everyone learns
                              differently. That is why we bring together
                              immersive video lessons for primary and secondary
                              school students in India created by progressive
                              teachers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="divHigh">
                          <div className="right_Colm">
                            <h4>Select languages you understand</h4>
                            <hr />
                            <div className="class_Colm">
                              {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                                (languageId) => {
                                  return (
                                    <div
                                      className="class_Form_Cont"
                                      key={languageId}
                                      // onClick={() =>
                                      //   onSelectLanguages(languageId)
                                      // }
                                    >
                                      <label className="classlctBox">
                                        {LANGUAGES_DICT[languageId]}
                                        <input
                                          className="lang_Check"
                                          type="checkbox"
                                          name=""
                                          checked={state.profile.languages.includes(
                                            languageId
                                          )}
                                          value={languageId}
                                          onChange={onSelectLanguages}
                                        />
                                        <span className="class_check"></span>
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <br />
                            <br />
                            <div className="contmid">
                              <button
                                id="startsession"
                                className="finishBtn"
                                type="button"
                                onClick={() => onSubmitProfile()}
                              >
                                Finish
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WelcomeMain;
