import { IBoardDetails, IClassDetails, IMediumDetails, IStandardAPIResponse, IStudentProfile } from "../vm";
import { httpClient } from "./UtilService";

export async function getAllBoards(): Promise<IStandardAPIResponse<IBoardDetails>> {
    try {
        let res = await httpClient<IBoardDetails>(`get_board`, 'GET');
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getAllClasses(): Promise<IStandardAPIResponse<Array<IClassDetails>>> {
    try {
        let res = await httpClient<Array<IClassDetails>>(`get_class`, 'GET');
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function getAllMediums(): Promise<IStandardAPIResponse<IMediumDetails>> {
    try {
        let res = await httpClient<IMediumDetails>(`get_medium`, 'GET');
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function createStudentProfile(profile: IStudentProfile): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`create_user_profile`, 'POST', profile);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}

export async function updateStudentProfile(profile: IStudentProfile): Promise<IStandardAPIResponse<any>> {
    try {
        let res = await httpClient<any>(`update_user_profile`, 'POST', profile);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
}